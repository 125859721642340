import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CContainer, CSpinner, CFade, CCardBody } from '@coreui/react'

// routes config
import routes from '../routes'
import auth from 'src/services/_helfers/auth'
import { Redirect } from 'react-router-dom'
import { Switch } from 'react-router-dom'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const AppContent = () => {
  localStorage.getItem("state")
  return (
    <CContainer lg style={{ paddingBottom: '10px' }} className='mt-2'>
      <Suspense fallback={loading}>
        <Switch>
          {routes.map((route, idx) => {
            return route.component ? (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={(props) =>
                  auth.isAuthenticated === true ? (
                    <div>
                      <route.component {...props} />
                    </div>
                  ) : (
                    <Redirect to={{ pathname: "/login" }} />
                  )
                }
              />
            ) : null;
          })}
          <Redirect to={{ pathname: "/event-list" }} />
        </Switch>
      </Suspense>
    </CContainer>
  )
}

export default React.memo(AppContent)
