import { useNavigate } from 'react-router-dom';
class Auth {
  
    constructor() {

    if (localStorage.getItem("state")) {
      this.isAuthenticated = true;
      this.isAuthenticatedFun();
    } else {
      this.isAuthenticated = false;
    }
  }

  isAuthenticatedFun(){
    if(this.isAuthenticated===true){
      return this.isAuthenticated;
    }else{
      window.location.href='/#/login';
    }
  }
}

export default new Auth();
