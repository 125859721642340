import React from 'react'
// Pages
// const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const EventList=React.lazy(() => import('./views/pages/event/event-list'))
const DriverList=React.lazy(() => import('./views/pages/event/driver-list'))
const ReportList=React.lazy(() => import('./views/pages/event/report-list'))
const UserList=React.lazy(() => import('./views/pages/event/user-list'))
const CreateEvent=React.lazy(() => import('./views/pages/event/create-event'))

const CategoryList=React.lazy(() => import('./views/pages/category/category'))

const CreateCategory=React.lazy(() => import('./views/pages/category/create-catergory'))
const WaitingList=React.lazy(() => import('./views/pages/waitinglist/waitinglist'))
const PendingUserList=React.lazy(() => import('./views/pages/pending_user/pending_user'))

const routes = [
  { path: '/', exact: true, name: 'CMS' },
  // { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/event-list', exact: true, name: 'Events', component: EventList },
  { path: '/driver-list', exact: true, name: 'Driver', component: DriverList },
  { path: '/report-list', exact: true, name: 'Report', component: ReportList },
  { path: '/user-list', exact: true, name: 'User', component: UserList },
  { path: '/create-event', exact: true, name: 'Create Events', component: CreateEvent },
  { path: '/category-list', exact: true, name: 'Create List', component: CategoryList },
  { path: '/create-category', exact: true, name: 'Create Category', component: CreateCategory },
  { path: '/waiting-list', exact: true, name: 'Waiting List', component: WaitingList },
  { path: '/pending_user_list', exact: true, name: 'Pending Users List', component: PendingUserList }

]

export default routes

