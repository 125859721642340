import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { AppSidebarNav } from './AppSidebarNav'
import logoNegative  from 'src/assets/images/Logo-for-verification.png'
// import  {logoNegative}  from 'src/assets/images/logo'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigation from '../_nav'
import { AUTENTICATE_API } from 'src/services/Api/authenticationApi';


var role_id = ''
var navVal;
var navVals = [];
// if (localStorage.getItem('state') != null) {
//   let state = localStorage.getItem('state');
//   state = JSON.parse(state)
//   role_id = state['userInfo']['role_id']
// }

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const [menu, setMenu] = useState([]);
  const [menuForEditor, setMenuEditor] = useState([]);
  const [menu_Item, setItme] = useState([]);
  useEffect(() => {
    getMenu()
  }, [])

  const getMenu = () => {
    // AUTENTICATE_API.getMenu().then(res => {
    //   if (res && res?.data) {
    //     res?.data?.map(val => {
    //       if (val?.status === 1 && role_id == 2 && val?.for_editor == 2) {
    //         let nv = navigation?.filter(fl => fl.name == val.name);
    //         navVals.push(nv[0])
    //       }
    //       if (val?.status === 1 && role_id == 3 && val?.for_approvar == 3) {
    //         let nv = navigation?.filter(fl => fl.name == val.name);
    //         navVals.push(nv[0])
    //       }
    //     })
    //     if (role_id == 2) {
    //       setMenuEditor(navVals);
    //     }
    //     if (role_id == 3) {
    //       setMenu(navVals)
    //     }
    //   }
    // });
  }

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <img className="sidebar-brand-full" src={logoNegative} width={"100"}/>
        {/* <CIcon className="sidebar-brand-narrow w-100" icon={sygnet} height={35} /> */}
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
