import React from 'react'
import { CFooter } from '@coreui/react'

const AppFooter = () => {
  const redirectFun = () => {
    window.open(`https://www.tactionsoftware.com/`, '_blank');
  }
  return (
    <CFooter>
      <div style={{margin:'auto'}}>
        <span className="ms-1">&#169; WarumLeer.</span>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
