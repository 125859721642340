
import axios from "axios";
// import jwt_decode from "jwt-decode";
// import { createHashHistory } from "history";
import jwt_decode from "jwt-decode";
// import { authHeader } from "./../_helfers/auth-header";
import { Urls } from "../Urls/apiUrl";
// const history = createHashHistory();
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
export const AUTENTICATE_API = {
  adminLogin,
  userVerification,
  createStaff,
  updateRolePermision,
  getStaff,
  getSingleStaff,
  updateStaff,
  deleteStaff,
  changePassword,
  setGlobalPassword,
  uploadPhoto,
  getRole,
  updateStaffStatus,
  getMenu,
  getUserList
};



function getUserList(id) {
  return axios.get(`https://api.drugnutritioninteractions.com/api/api/v1/Customers_detail_list/${id}`, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {

      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Unauthorized user!', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
          localStorage.removeItem('state');
          setTimeout(() => {
            window.location.href = "/#/login"
          }, 2000)
          // toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        }
    });
}
function getMenu() {
  return axios.get(Urls + `get_Menu`, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {

      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Unauthorized user!', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
          localStorage.removeItem('state');
          setTimeout(() => {
            window.location.href = "/#/login"
          }, 2000)
          
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        }
    });
}


function updateStaffStatus(data) {
  return axios.post(Urls + `updateStaffStatus`, data, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {

      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Unauthorized user!', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
          localStorage.removeItem('state');
          setTimeout(() => {
            window.location.href = "/#/login"
          }, 2000)
          // toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        }
    });
}

function getRole(data, id) {
  return axios.post(Urls + `get_role`, data, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {

      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Unauthorized user!', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
          localStorage.removeItem('state');
          setTimeout(() => {
            window.location.href = "/#/login"
          }, 2000)
          // toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        }
    });
}

function uploadPhoto(data, id) {
  return axios.post(Urls + `upload_photo?staff_id=${id}`, data, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {

      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Unauthorized user!', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
          localStorage.removeItem('state');
          setTimeout(() => {
            window.location.href = "/#/login"
          }, 2000)
          // toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        }
    });
}

function setGlobalPassword(data) {
  return axios.post(Urls + `set_Global_Password`, data, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {

      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Unauthorized user!', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
          localStorage.removeItem('state');
          setTimeout(() => {
            window.location.href = "/#/login"
          }, 2000)
          // toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        }
    });
}


function changePassword(data) {
  return axios.post(Urls + `change_password`, data, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {

      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Unauthorized user!', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
          localStorage.removeItem('state');
          setTimeout(() => {
            window.location.href = "/#/login"
          }, 2000)
          // toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        }
    });
}

function deleteStaff(data) {
  return axios.post(Urls + `deleteStaff`, data, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {

      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Unauthorized user!', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
          localStorage.removeItem('state');
          setTimeout(() => {
            window.location.href = "/#/login"
          }, 2000)
          // toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        }
    });
}

function updateStaff(data) {
  return axios.post(Urls + `updateStaff`, data, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {

      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Unauthorized user!', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
          localStorage.removeItem('state');
          setTimeout(() => {
            window.location.href = "/#/login"
          }, 2000)
          // toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        }
    });
}

function getSingleStaff(id) {
  return axios.get(Urls + `getSingleStaffs?staff_id=${id}`, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {

      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Unauthorized user!', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
          localStorage.removeItem('state');
          setTimeout(() => {
            window.location.href = "/#/login"
          }, 2000)
          // toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        }
    });
}

function getStaff(id) {
  return axios.get(Urls + `getStaffs?staff_id=${id}`, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {

      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Unauthorized user!', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
          localStorage.removeItem('state');
          setTimeout(() => {
            window.location.href = "/#/login"
          }, 2000)
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        }
    });
}

function adminLogin(requestOptions) {
  return axios.post(Urls + "warumleer_staff_login", requestOptions)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Invalid Credentials', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        }
    });
}

function userVerification(requestOptions) {
  return axios.post(Urls + "userVerification", requestOptions)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {

      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Unauthorized user!', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        }
    });
}



function createStaff(requestOptions) {
  return axios.post(Urls + "createStaff", requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {

      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Unauthorized user!', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
          localStorage.removeItem('state');
          setTimeout(() => {
            window.location.href = "/#/login"
          }, 2000)
          // toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        }
    });
}

function updateRolePermision(requestOptions) {
  return axios.post(Urls + "updateRolePermision", requestOptions, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {

      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Unauthorized user!', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
          localStorage.removeItem('state');
          setTimeout(() => {
            window.location.href = "/#/login"
          }, 2000)
          // toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        }
    });
}

function getToken() {
  var t = localStorage.getItem("state");
  t = JSON.parse(t)
  if (t !== null) {
    if (t === undefined || t == null) {
      return false;
    }
    const decoded = jwt_decode(t?.token);
    if (
      t !== null || t !== undefined && decoded.email_id === t?.userInfo?.email_id &&
      decoded.staff_id == t?.userInfo?.staff_id
    ) {
      return "Bearer " + t?.token;
    } else {
      return false
    }
  } else {
    setTimeout(() => {
      window.location.href = "/login"
    }, 2000)
  }
}
