import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilCalendar,cilList,cilTags,cilGolfAlt,cilBank,cilNotes,cilFile,cilUser
} from '@coreui/icons'
import { CNavGroup, CNavItem } from '@coreui/react'
import waiting from "./assets/images/file.png"
import docimentapproval from "./assets/images/docimentapproval.png"
import driving from "./assets/images/driver.png"
import planning from "./assets/images/planning.png"
import clipboard from "./assets/images/clipboard.png"
import pending from "./assets/images/pending.png"
const _nav = [
  // {
  //   component: CNavItem,
  //   name: 'Dashboard',
  //   to: '/dashboard',
  //   icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />
  // },
  {
    component: CNavItem,
    name: 'Document Approval',
    to: '/event-list',
    icon:  <img src={docimentapproval} className="nav_icon_" />
  },
  {
    component: CNavItem,
    name: 'Driver List',
    to: '/driver-list',
    icon:  <img src={driving} className="nav_icon_" />
  },
  {
    component: CNavItem,
    name: 'User List',
    to: '/user-list',
    icon:  <img src={planning} className="nav_icon_" />
  },
  {
    component: CNavItem,
    name: 'Waiting list',
    to: '/waiting-list',
    icon: <img src={waiting} className="nav_icon_" />
  },
  {
    component: CNavItem,
    name: 'Report List',
      to: '/report-list',
    icon:  <img src={clipboard} className="nav_icon_" />
  },
  {
    component: CNavItem,
    name: 'Pending Users List',
      to: '/pending_user_list',
    icon:  <img src={pending} className="nav_icon_" />
  },
  // {
  //   component: CNavItem,
  //   name: 'Category Management',
  //   to: '/category-list',
  //   icon: <CIcon icon={cilList} customClassName="nav-icon" />
  // },
  // {
  //   component: CNavItem,
  //   name: 'Ticket Category',
  //   to: '/event-list',
  //   icon: <CIcon icon={cilTags} customClassName="nav-icon" />
  // },
  // {
  //   component: CNavItem,
  //   name: 'Arena  Management',
  //   to: '/event-list',
  //   icon: <CIcon icon={cilGolfAlt} customClassName="nav-icon" />
  // },
  // {
  //   component: CNavItem,
  //   name: 'Bank  chanel Management',
  //   to: '/event-list',
  //   icon: <CIcon icon={cilBank} customClassName="nav-icon" />
  // },
  // {
  //   component: CNavItem,
  //   name: 'Reoprt',
  //   to: '/event-list',
  //   icon: <CIcon icon={cilNotes} customClassName="nav-icon" />
  // }
]

export default _nav
