import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import {
  cilBell,
  cilCreditCard,
  cilCommentSquare,
  cilEnvelopeOpen,
  cilFile,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import avatar8 from './../../assets/images/headerLogo.png'

const AppHeaderDropdown = () => {
  const [image, setImage] = useState(avatar8);
  useEffect(() => {
    let storage = localStorage.getItem('state');
    storage = JSON.parse(storage);
    setImage(storage?.userInfo?.image)
  }, [image]);
  const history = useHistory();
  // let navigate = useNavigate();
  const logOut = () => {
    localStorage.removeItem('state');
    toast.warning('Log Out Succesfully..', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
    setTimeout(() => {
      history.push('/login')
    }, 1000)
  }
  return (
    <>
      <ToastContainer style={{ zIndex: 999 }} />
      <CDropdown variant="nav-item">
        <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
          Admin
          {/* <img src={localStorage.getItem('avtar') == null ? `${image}` : localStorage.getItem('avtar')} width="30" height="29" style={{ border: '1px solid #ddd4d4', borderRadius: '20px' }} /> */}
        </CDropdownToggle>
        <CDropdownMenu className="pt-0" placement="bottom-end">
          {/* <CDropdownHeader className="bg-light fw-semibold py-2">Settings</CDropdownHeader>
          <CDropdownItem onClick={(e) => {
            history.push('/setting')
          }} style={{ cursor: 'pointer' }}>
            <CIcon icon={cilSettings} className="me-2" />
            Settings
          </CDropdownItem>
          <CDropdownDivider /> */}
          <CDropdownItem style={{ cursor: 'pointer' }} onClick={logOut}>
            <CIcon icon={cilLockLocked} className="me-2" />
            Logout
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown></>
  )
}

export default AppHeaderDropdown
